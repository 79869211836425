<template>
  <div class="container">
    <div class="wedge-glu common-pane">
      <div class="title">
        {{ $t('message.wedge.glue.title') }}
      </div>
      <div class="wedge-glu__right">
        <div class="right__tooltip">
          <div class="text">
            {{ $t('message.wedge.glue.tooltip') }}
          </div>
          <tippy interactive placement="right" :maxWidth="181">
            <template #trigger>
              <span class="icon"></span>
            </template>
            <span class="fastener-info__attach-tooltip">
              {{ $t('message.wedge.glue.tooltipText') }}
            </span>
          </tippy>
        </div>
        <wedge-square-input :square="glueSquare" @updateSquare="updateSquare" ref="glu" />
        <!--<div class="right__input">
          <div class="icon" @click="focusInput">
            <img src="@/assets/img/icons/wedge&#45;&#45;glu-edit.svg" alt="">
          </div>
          <input type="number" v-model="computedGlueSquare" :readonly="!isEditable" ref="input">
          <div class="units">{{ $t('message.units.m2') }}</div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import WedgeSquareInput from '@/components/smart/Wedge/WedgeSquareInput'

export default {
  components: { WedgeSquareInput },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_WEDGE_PARAM: 'UPDATE_SECTOR_WEDGE_PARAM'
    }),
    // focusInput() {
    //   this.isEditable = true;
    //   this.$refs.input.focus();
    // },
    updateSquare(value) {
      if (value !== this.glueSquare) {
        this.UPDATE_SECTOR_WEDGE_PARAM({
          sectorIndex: this.sectorIndex,
          param: 'glue',
          value: value
        })
      }
    }
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    glueSquare() {
      return this.sectorFromState.wedge.glue
    }
    // computedGlueSquare: {
    //   get() {
    //     return this.glueSquare
    //   },
    //   set(value) {
    //     if (!isNaN(parseFloat(value)) && value >= 0) {
    //       this.UPDATE_SECTOR_WEDGE_GLUE({
    //         sectorIndex: this.sectorIndex,
    //         value: value
    //       })
    //     }
    //   }
    // }
  }
}
</script>

<style scoped lang="sass">
.wedge
  &-glu
    display: grid
    +media((grid-template-columns: (320: rem(16) 1fr rem(8) 1fr rem(16), 576: 1fr 20fr 1fr 26fr 1fr, 960: 1fr 25fr 1fr 21fr 1fr)))
    +media((padding: (320: rem(16) 0, 960: rem(12) 0)))
    +media((margin: (320: rem(8) auto 0, 960: rem(16) 0 0)))
    +media((width: (320: 100%, 650: 92%, 960: 100%)))

    .title
      grid-column-start: 2
      @extend .fs-18
      font-weight: bold
      display: flex
      flex-direction: row
      align-items: center
      +media((font-size: (320: rem(14), 400: rem(16), 960: rem(18))))

    &__right
      grid-column-start: 4
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-end

      .right__tooltip
        display: flex
        height: 100%
        flex-direction: row
        @extend .fs-12
        color: $light-black
        align-items: center

        .text
          +media((display: (320: none, 576: block)))

        .icon
          margin: 0 rem(14)
          height: rem(20)
          width: rem(20)
          outline: none
          box-shadow: none
          display: block
          background-image: url("~@/assets/img/icons/info--default.svg")

          &:hover
            background-image: url("~@/assets/img/icons/info--active.svg")
</style>
