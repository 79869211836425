<template>
  <input type="number" v-model.number="computedCount" />
</template>

<script>
import { mapMutations } from 'vuex'
import { isValidNumber } from '@/utils'

export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    fastenerIndex: Number
  },
  methods: {
    ...mapMutations({
      UPDATE_WEDGE_FASTENER_COUNT: 'UPDATE_WEDGE_FASTENER_COUNT'
    })
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    computedCount: {
      get() {
        return this.count
      },
      set(value) {
        if (isValidNumber(value)) {
          this.UPDATE_WEDGE_FASTENER_COUNT({
            sectorIndex: this.sectorIndex,
            fastenerIndex: this.fastenerIndex,
            value
          })
        }
      }
    }
  }
}
</script>

<style scoped></style>
