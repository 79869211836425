<template>
  <div class="wedge-input">
    <input type="number" v-model.number="computedCount" />
    <div class="units">{{ $t('message.units.pc') }}.</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { calculateGlueSquare } from '@/utils/customFunctions'
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    plateIndex: {
      type: Number,
      default: 0
    },
    slopeType: String
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_WEDGE_PLATE_COUNT: 'UPDATE_SECTOR_WEDGE_PLATE_COUNT',
      UPDATE_SECTOR_WEDGE_GLUE: 'UPDATE_SECTOR_WEDGE_GLUE'
    }),
    calculateGlueSquare(contrSlopePlates, mainSlopePlates) {
      return calculateGlueSquare(contrSlopePlates, mainSlopePlates)
    }
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    sectorSlopes() {
      return this.sectorFromState.wedge.slopes
    },

    computedCount: {
      get() {
        return this.count
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          this.UPDATE_SECTOR_WEDGE_PLATE_COUNT({
            sectorIndex: this.sectorIndex,
            slopeType: this.slopeType,
            plateIndex: this.plateIndex,
            count: value
          })
          this.UPDATE_SECTOR_WEDGE_GLUE({
            sectorIndex: this.sectorIndex,
            value: parseFloat(
              this.calculateGlueSquare(
                this.sectorSlopes.contrSlope.plates,
                this.sectorSlopes.mainSlope.plates
              ).toFixed(2)
            )
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.wedge-input
  +media((width: (320: rem(80), 960: rem(100))))
  height: rem(32)
  border-radius: rem(4)
  box-sizing: border-box
  border: rem(1) solid #D6D6D6
  display: flex
  flex-direction: row
  align-items: center
  input
    @extend .clear-input
    +media((padding: (320: 0 rem(7) 0 rem(8), 960: 0 rem(7) 0 rem(14))))
    width: 100%
    @extend .fs-14
    font-weight: bold
  .units
    padding-right: rem(7)
    @extend .fs-12
    color: #999999
    font-weight: bold
</style>
