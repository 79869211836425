<template>
  <div class="import-table">
    <div class="import-table__title">
      {{ $t('message.wedge.importTable.title') }}
    </div>
    <div class="import-table__errors" v-if="isErrorShow">
      <div class="error error--format" v-if="errorCode === 400">
        {{ $t('message.wedge.importTable.errors.format') }}
        <br />
        <a :href="templatePath" download>
          {{ $t('message.wedge.importTable.errors.download') }}</a
        >
      </div>
      <div class="error error--notFound" v-if="errorCode === 409">
        {{ $t('message.wedge.importTable.errors.notFound') }}
      </div>
      <SimpleButton color="red" @clickedFromSimpleBtn="back">
        {{ $t('message.wedge.importTable.continue') }}
      </SimpleButton>
    </div>
    <div class="import-table__interface" v-else>
      <div class="layer-select" v-if="filteredFasteners.length">
        <select name="algorithm" v-model="selectedAlgorithm" class="select">
          <option :value="fastener.id" v-for="fastener in filteredFasteners" :key="fastener.id">
            {{ fastener.name }}
          </option>
        </select>
      </div>
      <textarea
        @input="validateTable"
        class="import-table__field"
        :placeholder="`${$t('message.wedge.importTable.placeholder')}`"
      >
      </textarea>
      <SimpleButton color="green" @clickedFromSimpleBtn="importTable" :is-disabled="isBtnDisabled">
        {{ $t('message.wedge.importTable.btn') }}
      </SimpleButton>
      <message :type="'slopeDisclaimer'"></message>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import { getFastenerInfo } from '@/utils/customFunctions'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import { filterFasteners } from '@/utils'
import { importFromExcel } from '@/api'
import Message from '@/components/elements/Dom/Message'

const publicPath = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ACTION_URL : `${location.origin}`

export default {
  data: () => ({
    importedTable: '',
    isErrorShow: false,
    selectedAlgorithm: null,
    isTableValid: false,
    errorCode: null,
    templatePath: ''
  }),
  components: {
    Message,
    SimpleButton
  },
  methods: {
    back() {
      this.isErrorShow = false
      this.errorCode = null
      this.isTableValid = false
    },
    validateTable(e) {
      // status codes: 400 неверный, 409 не найде екн
      this.isTableValid = false
      let input = e.target.value
      input.split('\n')
      const output = input.split('\n').map(p => p.split('\t'))
      const normalizeOutput = output.map(p => p.slice(0, 2)) // [*]

      if (normalizeOutput.length) {
        this.importedTable = normalizeOutput // [*]
        this.isTableValid = true // [*]
        this.isErrorShow = false // [*]
        this.errorCode = null // [*]
      }
    },
    importTable() {
      this.$emit('toggleLoading')
      importFromExcel(this.$i18n.locale, this.importedTable, this.getBaseId, this.selectedAlgorithm)
        .then(response => {
          this.isErrorShow = false
          this.errorCode = null
          this.$emit('toggleLoading')
          this.$emit('importTable', response.data)
        })
        .catch(error => {
          this.$emit('toggleLoading')
          if (error.response.status === 400) {
            this.templatePath = `${publicPath}${error.response.data.excelTemplate}`
            this.showError(400)
          } else if (error.response.status === 409) {
            this.showError(409)
          }
        })
    },
    showError(code) {
      this.isErrorShow = true
      this.errorCode = code
      this.isTableValid = false
    }
  },
  mounted() {
    if (this.filteredFasteners.length) {
      const [firstFoundedAlgorithm] = this.filteredFasteners
      this.selectedAlgorithm = firstFoundedAlgorithm.id
    }
  },
  computed: {
    ...mapState({
      defaultBaseId: state => state.defaultBaseId,
      allFasteners: state => state.fasteners
    }),
    filteredFasteners() {
      return filterFasteners(this.getBaseId, 'wedge', this.allFasteners)
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    slopeLayer() {
      return this.sectorFromState.layers.findIndex(p => p.isSlope && p.isEnabled)
    },
    klinLayer() {
      return this.sectorFromState.layers[this.slopeLayer].items.findIndex(
        p => Object.prototype.hasOwnProperty.call(p, 'wedge') && p.wedge
      )
    },
    getBaseId() {
      const clonedLayers = cloneDeep(this.sectorFromState.layers)
      return getFastenerInfo(this.slopeLayer, this.klinLayer, clonedLayers, this.defaultBaseId)
        .fastenerBase
    },
    isBtnDisabled() {
      return this.selectedAlgorithm === null || !this.isTableValid
    },
    publicPath: () => publicPath
  }
}
</script>

<style scoped lang="sass">
.import-table
  // +media((max-width: (320: 92vw, 450: rem(400))))
  // +media((width: (320: 92vw, 450: rem(400))))
  // padding: rem(20) 0
  display: flex
  flex-direction: column
  +media((padding: (320: rem(40) rem(25), 600: rem(56) rem(60))))
  max-width: rem(520)
  width: 95vw
  box-sizing: border-box
  &__errors
    .simple-btn
      display: inline-flex
      width: auto
      height: rem(48)
    .error
      margin: rem(20) 0
      @extend .fs-14
      a
        color: $red
        transition: .5s
        font-weight: bold
        &:hover
          color: $red-hover
  &__title
    @extend .fs-24
    font-weight: bold
    +media((font-size: (320: rem(18), 600: rem(24))))
  .layer-select
    // width: calc(100% - 40px)
    margin: rem(20) auto 0
    width: 100%
    .select
      // padding: 0
    &:after
      content: ""
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: rem(12)
      height: rem(10)
      width: rem(10)
      background-image: url(~@/assets/img/select.svg)
      background-position: center
      background-repeat: no-repeat
      pointer-events: none
      background-size: contain
  &__error
    color: $red
    margin: rem(20) rem(20) 0
    @extend .fs-12
  &__field
    margin: rem(20) 0
    border: rem(1) solid #D6D6D6
    box-sizing: border-box
    border-radius: rem(4)
    resize: none
    min-height: rem(200)
    +media((padding: (320: rem(8), 960: rem(12))))
    width: 100%
  &__interface
    .simple-btn
      // width: calc(100% - 40px)
      width: 100%
      margin: auto
      text-align: left
      justify-content: flex-start
      height: rem(48)
</style>
